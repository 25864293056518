'use strict'

const CONSTANTS = require('../constants/constants');

/**
 * Function that returns current page from actual url
 * @returns {string|boolean}
 */
function getCurrentPage(){
    var url = window.location.href;
    var regex = /(?:\?|&)page=(\d+)(?=&|$)/;
    var match = url.match(regex);

    return match ? match[1] : false;
}


/**
 * Handles view list event push, by limiting 10 items for each event
 * @param data
 */
function pushViewListEvent(data){
    if(typeof dataLayer != 'undefined'){
        
        var originalSize = data.eventData.items.length;
        var iterations = Math.ceil(originalSize / 10);
        for(let i = 0; i < iterations; i++){
            var until = 10 * (i+1);
            if(originalSize - (i * 10) < 10)
                until = originalSize;
            dataLayer.push({
                event : CONSTANTS.EVENT_LIST_NAME.VIEW_LIST,
                ecommerce : {
                    items : data.eventData.items.slice((10 * i), until)
                }
            });
        }
    }
}


/**
 * Remove product event function
 */
function removeProduct(event, data){
    if(typeof dataLayer != 'undefined'){
        
        if (!data.analyticsData){
            console.error('Remove item analytics failed');
            return;
        }
    
        //STEP 2: Build and push data to analytics
        dataLayer.push(data.analyticsData);
    }
}


/**
 * Handles tile click event
 */
function clickTile(event, isWishlist){

    if(typeof dataLayer != 'undefined'){
        
        event.preventDefault();
        var $this = $(this);
        var contextEvent = $this.closest('[data-analytics-name]').data('analyticsName');
    
        let redirect = true;
        let detailViewEventUrl = $('body .js-ga4-dataset').data('viewevent');
    
        // IF QUICK BUY CLICKED DON'T REDIRECT
        if(event){
            if($(event.target).is('svg') || $(event.target).is('use') || $(event.target).hasClass('quickview') || $(event.target).hasClass('cc-icon--shopping-bag')){
                redirect = false;
            }
        } 
    
        //STEP 1 -- Get redirect url
        let analyticsUrl = $('#search-result-items').data('analytics');
        if(!analyticsUrl){
            analyticsUrl = $('.js-ga4-dataset').data('carouseltile');
            analyticsUrl = analyticsUrl.replace(/(context=)[^&]+/, `$1${contextEvent}`);
        }
        let $buttonElement = $(event.currentTarget);
        let pid = $buttonElement.find('.data-itemid').data('itemid')
        let variation = $buttonElement.find('.data-itemid').data('variation')
        let product = variation ? variation : pid;
    
        if(!product){
            product = event.currentTarget.dataset.pid;
        } 
    
        var redirectUrl = $buttonElement.find('.js-tile-url').attr('href');
        var index = Array.from(document.getElementsByClassName('js-tile')).indexOf(this) + 1;
    
        //STEP 2 -- Get analytics data
        $.ajax({
            type: 'GET',
            url: analyticsUrl + '&index=' + index + '&productString=' + product,
            success: function (data) {
                //STEP 3: Check data existence
                if (!data || !data.eventData){
                    // console.error('Tile click analytics failed');
    
                    //Redirect to destination anyways
                    if(redirect) handleClickLink(event, redirectUrl);
                    return;
                }
    
                //STEP 4: Build and push data to analytics
                dataLayer.push({
                    event : CONSTANTS.EVENT_LIST_NAME.SELECT_PRODUCT,
                    ecommerce : data.eventData
                });
    
                viewProductEvent(detailViewEventUrl, product, contextEvent);
    
                //STEP 5: Redirect to destination
                if(redirect && !isWishlist){
                    handleClickLink(event, redirectUrl);
                } 
            },
        });
    }
}

/**
 * Handles login form submit
 */
function submitLoginForm(event){

    if(typeof dataLayer != 'undefined'){
        
        event.preventDefault();
    
        const util = require('../util');
        var currentForm = $(this);
        if(!currentForm){
            console.error('Could not find the form: login error');
            return false;
        }
    
        //STEP 0 -- Understand if this is the first form submit
        if($(this).hasClass('fired'))
            return false;
        $(this).addClass('fired');
    
        //STEP 1 -- Submit form action
        $.ajax({
            type: 'POST',
            url: util.ajaxUrl(currentForm.attr('action')) + '&action=' + event.data.action,
            data: currentForm.serialize(),
            success: function (data) {
                //STEP 2: Check data existence
                if (!data){
                    console.error('Login update analytics failed');
                    return;
                }
    
                //STEP 3 -- Push data to analytics
                if(data.analyticsData)
                    dataLayer.push(data.analyticsData);
            },
        })
    }
}


/**
 * PRIVATE FUNCTIONS
 */


/**
 * Redirects to a given URL in a new tab if Ctrl/Click is pressed, otherwise in the same tab.
 */
function handleClickLink(event, redirectUrl){
    // Check if the Ctrl key is pressed
    if (event.ctrlKey || event.metaKey) {
        // Open the link in a new tab
        window.open(redirectUrl, '_blank');
    } else {
        // Open the link in the same tab
        location.href = redirectUrl;
    }
}

function viewProductEvent(url, product, contextEvent) {

    if (typeof dataLayer != 'undefined') {

        var urlEvent = $('.js-ga4-dataset').data('productdata');
        if (product) {
            urlEvent = urlEvent.split('productString')[0] + 'productString=' + product;

            if (contextEvent) {
                urlEvent += `&context=${contextEvent}`;
            }

            //STEP 1: Update cart
            $.ajax({
                type: 'GET',
                url: urlEvent,
                success: function (data) {
                    //STEP 1: Check data existence
                    if (!data || !data.eventData) {
                        console.error('PDP view analytics failed');
                        return;
                    }

                    //STEP 2: Build and push data to analytics
                    dataLayer.push({
                        event: CONSTANTS.EVENT_LIST_NAME.VIEW_PRODUCT,
                        ecommerce: data.eventData
                    });
                },
            });
        }
    }
}



module.exports = {
    pushViewListEvent : pushViewListEvent,
    getCurrentPage : getCurrentPage,
    removeProduct : removeProduct,
    clickTile : clickTile,
    submitLoginForm : submitLoginForm
}
